@import "_palette";
// Here you can add other styles
body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: #f0f3f5;
}

.sidebar {
  background-color: #ffffff !important;
  color: #fff !important;
  display: block;
}

.sidebar .nav-link {
  display: block;
  color: #004136 !important;
  text-decoration: none;
  background: rgba(255, 255, 255, 0);
  font-size: large;
  padding: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar .nav-link.active {
  color: #004136 !important;
  background: #f5f5ef;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
a:hover {
  background-color: #f5f5ef !important;
}

.sidebar .nav-link .nav-icon {
  display: inline-block;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.875rem;
  color: #004136 !important;
  text-align: center;
}

.app-header .nav-item .nav-link > .img-avatar,
.app-header .nav-item .avatar.nav-link > img {
  height: 35px;
  margin: 0 50px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px; /* 5px rounded corners */
}

.card {
  border-radius: none;
}

.label-not-found {
  text-align: center;
  color: #ff0000;
  width: 100%;
}

.btn-back {
  margin-right: 10px;
}

.modal-custom {
  padding: 20px;
  .modal-message {
    max-height: 200px;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8rem;
  }
}

.modal-custom h4 {
  margin-bottom: 20px;
  color: $color-green;
  text-transform: uppercase;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.modal-custom p {
  text-align: justify;
}

.btn {
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-radius: 20px;
  min-width: 200px;
  text-transform: uppercase;
}

.loading {
  position: relative;
  width: 100%;
  margin-top: 60px;
}
.loading-icon {
  margin: 0;
  margin: 0 auto;
  margin-top: 15%;
}
.loading-container .loading {
  margin-top: 0;
}
.loading-container .modal-backdrop.show {
  opacity: 0.3;
}

.row {
  margin: 0 !important;
}

.btn-outline-secondary {
  color: #525149 !important;
}

//Select
input[type="text"].form-control,
select.form-control {
  border: 0;
  border-bottom: 2px solid;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
select {
  border: 1px solid #ccc;
  height: 26px;
  line-height: 18px;
  margin: 0;
  padding: 3px;
  background: rgba(255, 255, 255, 0) none no-repeat;
  cursor: pointer;
}

@media screen and (min-width: 0) {
  /* for relatively modern browsers including IE 8+ */
  select {
    border-radius: 4px;
    background-image: url(./assets/images/select-icon.png);
    background-position: -50px -50px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* for Webkit */
  select {
    -webkit-appearance: none;
    background-position: right center;
    padding: 3px 32px 3px 5px;
  }
}

@-moz-document url-prefix() {
  /* for Firefox */
  select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    background-position: right center;
    padding-right: 16px;
  }

  /* hides the dotted outline on focus in FF (See SO#3773430) */
  select:-moz-focusring {
    color: rgba(255, 255, 255, 0);
    text-shadow: 0 0 0 #000;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* for IE10+ */
  select::-ms-expand {
    display: none;
  }
  select {
    background-position: right center;
    padding-right: 30px;
  }
}
label {
  margin-top: 0.7rem;
  margin-bottom: 0.1rem;
  font-weight: bold;
}

.actions {
  margin-top: 14px;
  text-align: right;
}
.actions.align-right {
  .btn {
    margin-right: 0px !important;
    margin-left: 10px !important;
  }
}

.form-control:focus {
  box-shadow: none;
}

.no-padding {
  padding: 0;
}

.text-bold {
  font-weight: bold !important;
}
.btn,
.btn:active,
.btn:hover,
.btn:focus {
  background-color: $color-lightgreen !important;
  color: $color-green !important;
  font-weight: bold !important;
  border-color: $color-lightgreen !important;
}

.btn.btn-ecopetrol,
.btn.btn-ecopetrol:active,
.btn.btn-ecopetrol:hover,
.btn.btn-ecopetrol:focus {
  background-color: $color-lightgreen !important;
  color: $color-green !important;
  font-weight: bold !important;
  border-color: $color-lightgreen !important;
}
.btn.btn-eco-petrol-2 {
  .action-icon {
    margin-right: 5px;
  }
}
.btn.btn-eco-petrol-2.medium {
  .action-icon {
    margin-right: 3px;
  }
}

.btn.btn-eco-petrol-2.btn-primary,
.btn.btn-eco-petrol-2.btn-primary:active,
.btn.btn-eco-petrol-2.btn-primary:hover,
.btn.btn-eco-petrol-2.btn-primary:focus {
  background-color: $color-lightgreen !important;
  color: $color-green !important;
  font-weight: bold !important;
  font-size: 0.75rem;
  border-color: $color-lightgreen !important;
}
.btn.btn-eco-petrol-2.btn-secondary,
.btn.btn-eco-petrol-2.btn-secondary:active,
.btn.btn-eco-petrol-2.btn-secondary:hover,
.btn.btn-eco-petrol-2.btn-secondary:focus {
  background-color: rgba(255, 255, 255, 0) !important;
  color: $app-black !important;
  border-color: $dark-gray !important;
}
.btn.btn-eco-petrol-2.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn.btn-eco-petrol-2.btn-primary.focus,
.btn.btn-eco-petrol-2.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem $color-lightgreen;
}
.btn.btn-eco-petrol-2.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn.btn-eco-petrol-2.btn-secondary.focus,
.btn.btn-eco-petrol-2.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem $dark-gray;
}

.btn.btn-cancel,
.btn.btn-cancel:active,
.btn.btn-cancel:hover,
.btn.btn-cancel:focus {
  background-color: $color-lightgreen !important;
  color: $color-green !important;
  font-weight: bold !important;
  border-color: $color-lightgreen !important;
  min-width: auto !important;
}

.btn.btn-disabled,
.btn.btn-disabled:active,
.btn.btn-disabled:hover,
.btn.btn-disabled:focus {
  background-color: $color-lightgray !important;
  border-color: $color-lightgray !important;
  /*min-width: 250px !important;*/
  cursor: default;
}
.btn.btn-diabled.focus,
.btn.btn-disabled:focus {
  box-shadow: none !important;
}

// Icon buttons
.btn.button-icon {
  .subtitle {
    font-size: 0.7rem;
    color: $dark-gray;
    white-space: normal;
    font-weight: normal !important;
  }
}
.btn.button-icon.button-icon,
.btn.button-icon:active,
.btn.button-icon:hover,
.btn.button-icon:focus {
  background-color: #fff !important;
  color: $color-green !important;
  font-weight: bold !important;
  border-color: rgba(255, 255, 255, 0) !important;
  min-width: auto !important;
  padding: 10px;
  border-bottom: 3px solid $app-orange !important;
  border-bottom-right-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  text-transform: none;
  box-shadow: none !important;
}
.btn.button-icon.button-icon.disable,
.btn.button-icon.disable:active,
.btn.button-icon.disable:hover,
.btn.button-icon.disable:focus {
  border: 3px dotted rgba(0, 0, 0, 0.125) !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.cita-buttons {
  text-align: center;
  margin-top: 15px;
  white-space: nowrap;
  margin-bottom: 10px;
  .btn.button-icon {
    width: 120px;
    height: 120px;
  }
}

.medium-button-icon {
  border-left: 5px solid $app-yellow !important;
  border-top-left-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  background-color: #ffffff;
  cursor: pointer;
  h3.title {
    font-weight: bold;
    font-size: 0.8rem;
  }
}
.large-button-icon {
  margin-top: 10px;
  margin-bottom: 10px;
  .mega-subtitle {
    font-size: 0.8rem;
  }
  .image-container {
    padding-top: 1.25rem;
  }
  li {
    background: rgba(255, 255, 255, 0);
  }
}
.large-button-icon.collapsed {
  height: 130px;
}
.button-behavior {
  cursor: pointer;
  h3 {
    text-decoration: underline;
  }
}
.doctor.large-button-icon {
  border-bottom: 5px solid $app-orange !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.no-margin {
  margin: 0 !important;
}

.page-item .page-link {
  z-index: 1;
  color: $dark-green;
}
.page-item.active a.page-link,
.page-item.active a.page-link:hover {
  z-index: 1;
  color: $dark-green;
  background-color: $light-green !important;
  border-color: $light-green;
}

table {
  border-collapse: collapse !important;
  border-radius: 1em !important;
  overflow: hidden !important;
  border: none !important;
}

th {
  background: white !important;
  border-bottom: 2px solid white;
  padding: 7px !important;
  border: none;
}

td {
  background: white !important;
  border-bottom: 2px solid white;
  padding: 7px !important;

  border: 0px solid#e4e5e6 !important;
}

tr {
  border: 10px solid white;
}

em {
  font-weight: bold;
  text-decoration: underline;
  font-variant: small-caps;
}

.oga-box {
  border-width: 0 4 0 4;
  border-color: lightgray;
  border-style: dotted;
}

.border-card {
  border-top-color: red;
  border-right-color: blue;
  border-bottom-color: green;
  border-left-color: gold;
}

.border-card-left {
  border-left-color: orangered;
}

.border-card-top {
  border-top-color: red;
}

.border-card-mega {
  border-bottom-color: gold;
  border-bottom-width: 4px;
}

.border-card-right {
  border-right-color: gold;
}

.border-card-bottom {
  border-bottom-color: orangered;
  border-bottom-width: 4px;
}
.navbar > a:hover {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}
.navbar a:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar ul li a:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  padding-top: 15px;
  padding-bottom: 15px;
}
.user-card {
  background-color: rgba(255, 255, 255, 0);
}
.user-image {
  width: 60px;
  height: 60px;
  margin-top: 10px;
}
.user-title {
  font-weight: bold;
  font-size: 0.9rem;
  .bienvenido {
    font-size: 0.8rem;
  }
  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

body > div {
  z-index: auto !important;
}

// Datepicker
.react-datepicker-wrapper {
  display: block !important;
  @media (min-width: 330px) {
    display: inline-block !important;
  }
  @media (min-width: 774px) {
    display: block !important;
  }
  @media (min-width: 920px) {
    display: inline-block !important;
  }
}
.date-range,
.custom-date-picker {
  label,
  input {
    margin-right: 10px;
  }
  input {
    padding-left: 3px !important;
  }
}
.custom-date-picker {
  margin-top: 15px;
}
.react-datepicker__close-icon::after {
  background-color: $dark-gray !important;
  right: 10px !important;
}
.react-datepicker__input-container input {
  height: 30px;
}
.custom-date-input {
  background-color: #fff;
  border: 1px solid $light-gray !important;
  height: 30px;
  width: 170px;
  text-align: left;
  padding-left: 10px;
}
.terms {
  text-align: left;
}

// Notifications
.notification-ecopetrol-verde {
  background-color: $light-green;
  border-left: 8px solid darken($light-green, 15%);
  .notification-title,
  .notification-close span {
    color: darken($light-green, 40%);
  }
  .notification-message {
    color: darken($light-green, 30%);
    max-width: max-content;
  }
}
.notification-ecopetrol-naranja {
  background-color: $app-orange;
  border-left: 8px solid darken($app-orange, 15%);
  .notification-title,
  .notification-close span {
    color: lighter($app-orange, 40%);
  }
  .notification-message {
    color: lighter($app-orange, 30%);
    max-width: max-content;
  }
}
.notification-ecopetrol-amarillo {
  background-color: $app-yellow;
  border-left: 8px solid darken($app-yellow, 15%);
  .notification-title,
  .notification-close span {
    color: darken($app-yellow, 40%);
  }
  .notification-message {
    color: darken($app-yellow, 30%);
    max-width: max-content;
  }
}
.action-buttons {
  min-width: 210px;
  text-align: right;
  //white-space: nowrap;
}
.mobile-item .action-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  .btn {
    margin-top: 5px;
  }
}
