@import "_palette";
.sidebar-logo img {
  width: 150px;
  height: auto;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}
body {
  font-size: 0.875rem;
}
a,
a:hover {
  color: $dark-green;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
   * Sidebar
   */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  height: calc(100vh - 0px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
   * Navbar
   */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: rgba(255, 255, 255, 0);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

/*
   * Utilities
   */

.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.dashboard-title {
  color: #53534d;
  font-size: 1.08rem;
  font-weight: bolder;
}

.mega-image {
  width: 150px;
  height: 150px;
}
.mega-title {
  font-weight: bold;
  font-size: 1rem;
}
.mega-subtitle {
  font-weight: normal;
  font-size: 0.8rem;
  color: $dark-gray;
  @media (min-width: 768px) {
    font-size: 0.9rem;
  }
}
.mega-detail .img-icon {
  width: 20px;
  height: 20px;
}
.mega-detail p.card-text {
  margin-left: 10px;
  font-size: 0.8rem;
}

.doctor {
  border-bottom: 5px solid #c8d400 !important;
  border-bottom-right-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  background-color: #ffffff;
  .btn,
  .btn:active,
  .btn:hover,
  .btn:focus {
    font-size: 0.75rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    min-width: auto !important;
  }

  .change-button-container {
    text-align: center;
    margin-bottom: 5px;
  }
}
.modal-beneficiary-detail .buttons-row {
  .btn,
  .btn:active,
  .btn:hover,
  .btn:focus {
    font-size: 0.75rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    min-width: auto !important;
  }

  div {
    text-align: center;
  }
  .mega-container,
  .oga-container {
    margin-top: 0px;
  }
}

.doctor-img-flecha {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.beneficiary {
  border-left: 5px solid $app-yellow !important;
  border-top-left-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  background-color: #ffffff;
  cursor: pointer;
}
.beneficiary-title {
  font-weight: bold;
  font-size: 0.8rem;
}
.beneficiary-subtitle {
  font-weight: normal;
  font-size: 0.8rem;
  color: #868682;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .modal-beneficiary-detail {
    max-width: 540px;
  }
  .modal-proveedor-detail {
    max-width: 500px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .modal-beneficiary-detail {
    width: 600px;
  }
  .modal-proveedor-detail {
    max-width: 500px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .modal-beneficiary-detail {
    max-width: 900px;
    width: 900px;
  }
  .modal-proveedor-detail {
    max-width: 550px;
    width: 550px;
  }
}

.beneficiaryHeaderBg {
  background-color: #ffffff;
}

.modal-beneficiary-detail .beneficiaryDetail,
.modal-beneficiary-detail .doctors .card,
.modal-beneficiary-detail .doctors .list-group-item,
.modal-beneficiary-detail .modal-content,
.modal-beneficiary-detail .doctors {
  background-color: $dark-white;
}
.modal-beneficiary-detail .doctors .card.principal-doctor {
  background-color: #fff;
  .card,
  .card-body {
    background-color: #fff;
  }
}

.modal-hide-content {
  .modal-content {
    display: none;
  }
}
.modal-dialog.without-close {
  .modal-content {
    padding-top: 1rem;
  }
}

.beneficiaryHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  background-color: #fff;
}
.modal-beneficiary-detail .doctors .doctor.beneficiary-doctor {
  border: 0px;
  border-bottom: 0px solid #c8d400 !important;
}
.doctor.principal-doctor {
  .card-body {
    text-align: center;
  }
  .image-container {
    //display: inline;
  }
  img {
    float: none !important;
  }
  @media (min-width: 576px) {
    .image-container {
      height: "100%";
      width: 80;
    }
    .card-body {
      text-align: left;
    }
  }
}

.beneficiaryHeader h3 {
  color: #004237;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0px;
}
.beneficiaryHeader p {
  color: #b1b1ae;
  font-size: 0.8rem;
  margin-bottom: 0px;
}

.modal-beneficiary-detail {
  max-width: 100%;
  .mega-container,
  .oga-container {
    margin-top: 20px;
  }
  .doctor-body {
    padding: 10px;
    padding-bottom: 20px;
    background-color: $dark-white;
  }
  .mega-container {
    border-right: 0px dashed #c4c4c4 !important;
    @media (min-width: 768px) {
      border-right: 2px dashed #c4c4c4 !important;
    }
  }
}
.proveedorDetail,
.AppointmentHistoryDetail {
  .mega-container {
    border-right: none !important;
  }
}
.mega-detail {
  .detail-item {
    margin-top: 15px;
    margin-bottom: 15px;
    .title {
      color: $dark-gray;
      font-size: 0.8rem;
      margin-bottom: 0px;
    }
    .value {
      font-size: 0.9rem;
    }
  }
}

.doctor-not-assigned {
  background-color: rgba(255, 255, 255, 0);
  border: 3px dotted rgba(0, 0, 0, 0.125);
  padding-bottom: 10px;
  .card {
    background-color: rgba(255, 255, 255, 0);
  }
}

.interest-links {
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  @media (min-width: 768px) {
    width: 80%;
  }

  p {
    white-space: nowrap;
    font-size: 0.7rem;
  }
  .card {
    border-bottom: 3px solid $app-orange !important;
    border-bottom-right-radius: 1.2rem !important;
    border-bottom-left-radius: 1.2rem !important;
    //max-width: 130px;
    min-height: 50px;
    //width: 130px;
    height: 100px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-top: 10px;
    img {
      margin-top: 10px;
    }
  }
}
.interest-links.slide {
  width: 60%;
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 90%;
  }
}
.magazine {
  width: 90%;
  .card,
  .card-body {
    background-color: rgba(255, 255, 255, 0);
  }
  .magazine-image {
    width: 95%;
    height: auto;
  }
  @media (min-width: 768px) {
    width: 70%;
  }
}
.faq {
  .card-header {
    padding-right: 50px !important;
    .highlight {
      color: $app-orange;
    }
  }
  .card-body {
    .highlight {
      color: $app-orange;
    }
    color: $color-gray !important;
    border-left: 2px solid $color-gray !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    padding-left: 20px !important;
    padding-right: 50px !important;
  }
  .card,
  .card-body,
  .card-header,
  h5,
  .btn {
    background-color: rgba(255, 255, 255, 0) !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0px;
    white-space: initial !important;
    text-align: left !important;
    color: $dark-green !important;
    font-size: 1rem !important;
    text-transform: none !important;
  }
  .card {
    border-bottom: 2px solid $color-gray !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-top: 20px !important;
    padding-bottom: 20px !important;
    position: relative !important;
  }
  .card:last-child,
  .card.expanded {
    border-bottom: 0px solid $color-gray !important;
  }
  .card.expanded {
    padding-bottom: 0px !important;
  }
  .collapse.show {
    margin-top: 40px !important;
    margin-bottom: 20px !important;
  }
  .icon-action {
    position: absolute !important;
    right: 0px !important;
    top: 0px !important;
    cursor: pointer !important;
  }
}

/* Sliders */
.slick-prev:before,
.slick-next:before {
  color: $dark-green;
  font-size: 25px;
  cursor: pointer;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.5;
  color: $light-gray;
  cursor: auto;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: $dark-green;
}

.sidebar-links {
  img {
    margin-right: 15px;
  }
  .inactive a {
    color: $color-gray;
  }
  a {
    font-size: 0.94rem;
  }
}
.hamburguer {
  margin-top: 20px;
  margin-left: 0px;
  display: block;
  float: left;
  .hamburguer-icon {
    font-size: 1.5em;
  }
}
.header-logo img {
  width: 150px;
  height: auto;
}
.page-footer {
  padding-left: 15px;
  padding-right: 15px;
}
// Footer
.page-footer.sticky {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color: $dark-gray;
  font-size: 0.72rem;
  font-weight: 500;
  a {
    font-weight: 700;
  }
}
.footer-copyright {
  font-size: 0.875rem;
}

.principal-container {
  margin-bottom: 50px;
}
.principal-title {
  margin-bottom: 1.4rem !important;
  color: $dark-green;
  h1 {
    font-weight: bolder;
  }
}
h3 {
  color: $dark-green;
  font-size: 1.5rem;
}
//breadcrumb
.breadcrumb {
  background-color: rgba(255, 255, 255, 0);
  padding: 0rem 1rem;
  margin-bottom: 0rem;
  //padding-left: 0;
}
.breadcrumb-item {
  font-size: 0.75rem;
}
.breadcrumb-item a {
  color: $dark-gray;
  text-decoration: underline;
}
.breadcrumb-item.active {
  color: $dark-green;
}
.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0.2rem;
  color: $dark-gray;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.2rem;
}

h2 {
  color: $dark-gray;
  font-size: 1.05rem;
  font-weight: bold;
  margin-bottom: 25px;
}
.modal-dialog.login-message.cancel-appointment {
  text-align: left;
  .modal-content h5 {
    text-align: center;
  }
  .modal-body {
    padding-left: 40px;
    padding-right: 40px;
  }
}
