@import "../../../scss/palette";

.grecaptcha-badge {
  visibility: hidden;
}
.recaptcha-text-container {
  color: #212529;
  color: #c1c3c5;
  font-size: 0.7rem;
  text-align: center;
  margin-top: 10px;
  a {
    color: $dark-green;
    color: #8ea7a2;
  }
}
