/*COLOR*/
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
.color-palette-green {
  color: #004136; }

.color-palette-lightgreen {
  color: #c8d400; }

.color-palette-yellow {
  color: #ffdd00; }

.color-palette-blue {
  color: #002e5f; }

.color-palette-orange {
  color: #ee7402; }

.color-palette-lightgray {
  color: #c4c4c4; }

.color-palette-gray {
  color: #525149; }

/*BACKGROUND*/
.background-palette-green {
  background-color: #004136; }

.background-palette-lightgreen {
  background-color: #c8d400; }

.background-palette-yellow {
  background-color: #ffdd00; }

.background-palette-blue {
  background: #002e5f; }

.background-palette-orange {
  background-color: #ee7402; }

.background-palette-lightgray {
  background-color: #c4c4c4; }

.background-palette-gray {
  background-color: #525149; }

/*BORDER*/
.border-palette-green {
  border-color: #004136; }

.border-palette-lightgreen {
  border-color: #c8d400; }

.border-palette-yellow {
  border-color: #ffdd00; }

.border-palette-blue {
  border: #002e5f; }

.border-palette-orange {
  border-color: #ee7402; }

.border-palette-lightgray {
  border-color: #c4c4c4; }

.border-palette-gray {
  border-color: #525149; }

body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: #f0f3f5; }

.sidebar {
  background-color: #ffffff !important;
  color: #fff !important;
  display: block; }

.sidebar .nav-link {
  display: block;
  color: #004136 !important;
  text-decoration: none;
  background: rgba(255, 255, 255, 0);
  font-size: large;
  padding: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px; }

.sidebar .nav-link.active {
  color: #004136 !important;
  background: #f5f5ef;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px; }

a:hover {
  background-color: #f5f5ef !important; }

.sidebar .nav-link .nav-icon {
  display: inline-block;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.875rem;
  color: #004136 !important;
  text-align: center; }

.app-header .nav-item .nav-link > .img-avatar,
.app-header .nav-item .avatar.nav-link > img {
  height: 35px;
  margin: 0 50px; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  /* 5px rounded corners */ }

.card {
  border-radius: none; }

.label-not-found {
  text-align: center;
  color: #ff0000;
  width: 100%; }

.btn-back {
  margin-right: 10px; }

.modal-custom {
  padding: 20px; }
  .modal-custom .modal-message {
    max-height: 200px;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8rem; }

.modal-custom h4 {
  margin-bottom: 20px;
  color: #004136;
  text-transform: uppercase;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold; }

.modal-custom p {
  text-align: justify; }

.btn {
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-radius: 20px;
  min-width: 200px;
  text-transform: uppercase; }

.loading {
  position: relative;
  width: 100%;
  margin-top: 60px; }

.loading-icon {
  margin: 0;
  margin: 0 auto;
  margin-top: 15%; }

.loading-container .loading {
  margin-top: 0; }

.loading-container .modal-backdrop.show {
  opacity: 0.3; }

.row {
  margin: 0 !important; }

.btn-outline-secondary {
  color: #525149 !important; }

input[type="text"].form-control,
select.form-control {
  border: 0;
  border-bottom: 2px solid;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

select {
  border: 1px solid #ccc;
  height: 26px;
  line-height: 18px;
  margin: 0;
  padding: 3px;
  background: rgba(255, 255, 255, 0) none no-repeat;
  cursor: pointer; }

@media screen and (min-width: 0) {
  /* for relatively modern browsers including IE 8+ */
  select {
    border-radius: 4px;
    background-image: url(./assets/images/select-icon.png);
    background-position: -50px -50px; } }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* for Webkit */
  select {
    -webkit-appearance: none;
    background-position: right center;
    padding: 3px 32px 3px 5px; } }

@-moz-document url-prefix() {
  /* for Firefox */
  select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    background-position: right center;
    padding-right: 16px; }
  /* hides the dotted outline on focus in FF (See SO#3773430) */
  select:-moz-focusring {
    color: rgba(255, 255, 255, 0);
    text-shadow: 0 0 0 #000; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* for IE10+ */
  select::-ms-expand {
    display: none; }
  select {
    background-position: right center;
    padding-right: 30px; } }

label {
  margin-top: 0.7rem;
  margin-bottom: 0.1rem;
  font-weight: bold; }

.actions {
  margin-top: 14px;
  text-align: right; }

.actions.align-right .btn {
  margin-right: 0px !important;
  margin-left: 10px !important; }

.form-control:focus {
  box-shadow: none; }

.no-padding {
  padding: 0; }

.text-bold {
  font-weight: bold !important; }

.btn,
.btn:active,
.btn:hover,
.btn:focus {
  background-color: #c8d400 !important;
  color: #004136 !important;
  font-weight: bold !important;
  border-color: #c8d400 !important; }

.btn.btn-ecopetrol,
.btn.btn-ecopetrol:active,
.btn.btn-ecopetrol:hover,
.btn.btn-ecopetrol:focus {
  background-color: #c8d400 !important;
  color: #004136 !important;
  font-weight: bold !important;
  border-color: #c8d400 !important; }

.btn.btn-eco-petrol-2 .action-icon {
  margin-right: 5px; }

.btn.btn-eco-petrol-2.medium .action-icon {
  margin-right: 3px; }

.btn.btn-eco-petrol-2.btn-primary,
.btn.btn-eco-petrol-2.btn-primary:active,
.btn.btn-eco-petrol-2.btn-primary:hover,
.btn.btn-eco-petrol-2.btn-primary:focus {
  background-color: #c8d400 !important;
  color: #004136 !important;
  font-weight: bold !important;
  font-size: 0.75rem;
  border-color: #c8d400 !important; }

.btn.btn-eco-petrol-2.btn-secondary,
.btn.btn-eco-petrol-2.btn-secondary:active,
.btn.btn-eco-petrol-2.btn-secondary:hover,
.btn.btn-eco-petrol-2.btn-secondary:focus {
  background-color: rgba(255, 255, 255, 0) !important;
  color: #000000 !important;
  border-color: #525149 !important; }

.btn.btn-eco-petrol-2.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn.btn-eco-petrol-2.btn-primary.focus,
.btn.btn-eco-petrol-2.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #c8d400; }

.btn.btn-eco-petrol-2.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn.btn-eco-petrol-2.btn-secondary.focus,
.btn.btn-eco-petrol-2.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem #525149; }

.btn.btn-cancel,
.btn.btn-cancel:active,
.btn.btn-cancel:hover,
.btn.btn-cancel:focus {
  background-color: #c8d400 !important;
  color: #004136 !important;
  font-weight: bold !important;
  border-color: #c8d400 !important;
  min-width: auto !important; }

.btn.btn-disabled,
.btn.btn-disabled:active,
.btn.btn-disabled:hover,
.btn.btn-disabled:focus {
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
  /*min-width: 250px !important;*/
  cursor: default; }

.btn.btn-diabled.focus,
.btn.btn-disabled:focus {
  box-shadow: none !important; }

.btn.button-icon .subtitle {
  font-size: 0.7rem;
  color: #525149;
  white-space: normal;
  font-weight: normal !important; }

.btn.button-icon.button-icon,
.btn.button-icon:active,
.btn.button-icon:hover,
.btn.button-icon:focus {
  background-color: #fff !important;
  color: #004136 !important;
  font-weight: bold !important;
  border-color: rgba(255, 255, 255, 0) !important;
  min-width: auto !important;
  padding: 10px;
  border-bottom: 3px solid #EE7402 !important;
  border-bottom-right-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  text-transform: none;
  box-shadow: none !important; }

.btn.button-icon.button-icon.disable,
.btn.button-icon.disable:active,
.btn.button-icon.disable:hover,
.btn.button-icon.disable:focus {
  border: 3px dotted rgba(0, 0, 0, 0.125) !important;
  background-color: rgba(255, 255, 255, 0) !important; }

.cita-buttons {
  text-align: center;
  margin-top: 15px;
  white-space: nowrap;
  margin-bottom: 10px; }
  .cita-buttons .btn.button-icon {
    width: 120px;
    height: 120px; }

.medium-button-icon {
  border-left: 5px solid #FFDD00 !important;
  border-top-left-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  background-color: #ffffff;
  cursor: pointer; }
  .medium-button-icon h3.title {
    font-weight: bold;
    font-size: 0.8rem; }

.large-button-icon {
  margin-top: 10px;
  margin-bottom: 10px; }
  .large-button-icon .mega-subtitle {
    font-size: 0.8rem; }
  .large-button-icon .image-container {
    padding-top: 1.25rem; }
  .large-button-icon li {
    background: rgba(255, 255, 255, 0); }

.large-button-icon.collapsed {
  height: 130px; }

.button-behavior {
  cursor: pointer; }
  .button-behavior h3 {
    text-decoration: underline; }

.doctor.large-button-icon {
  border-bottom: 5px solid #EE7402 !important; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.no-margin {
  margin: 0 !important; }

.page-item .page-link {
  z-index: 1;
  color: #004136; }

.page-item.active a.page-link,
.page-item.active a.page-link:hover {
  z-index: 1;
  color: #004136;
  background-color: #C8D400 !important;
  border-color: #C8D400; }

table {
  border-collapse: collapse !important;
  border-radius: 1em !important;
  overflow: hidden !important;
  border: none !important; }

th {
  background: white !important;
  border-bottom: 2px solid white;
  padding: 7px !important;
  border: none; }

td {
  background: white !important;
  border-bottom: 2px solid white;
  padding: 7px !important;
  border: 0px solid #e4e5e6 !important; }

tr {
  border: 10px solid white; }

em {
  font-weight: bold;
  text-decoration: underline;
  font-variant: small-caps; }

.oga-box {
  border-width: 0 4 0 4;
  border-color: lightgray;
  border-style: dotted; }

.border-card {
  border-top-color: red;
  border-right-color: blue;
  border-bottom-color: green;
  border-left-color: gold; }

.border-card-left {
  border-left-color: orangered; }

.border-card-top {
  border-top-color: red; }

.border-card-mega {
  border-bottom-color: gold;
  border-bottom-width: 4px; }

.border-card-right {
  border-right-color: gold; }

.border-card-bottom {
  border-bottom-color: orangered;
  border-bottom-width: 4px; }

.navbar > a:hover {
  background-color: rgba(255, 255, 255, 0);
  color: white; }

.navbar a:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  padding-top: 15px;
  padding-bottom: 15px; }

.navbar ul li a:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  padding-top: 15px;
  padding-bottom: 15px; }

.user-card {
  background-color: rgba(255, 255, 255, 0); }

.user-image {
  width: 60px;
  height: 60px;
  margin-top: 10px; }

.user-title {
  font-weight: bold;
  font-size: 0.9rem; }
  .user-title .bienvenido {
    font-size: 0.8rem; }
  @media (min-width: 768px) {
    .user-title {
      font-size: 1rem; } }

body > div {
  z-index: auto !important; }

.react-datepicker-wrapper {
  display: block !important; }
  @media (min-width: 330px) {
    .react-datepicker-wrapper {
      display: inline-block !important; } }
  @media (min-width: 774px) {
    .react-datepicker-wrapper {
      display: block !important; } }
  @media (min-width: 920px) {
    .react-datepicker-wrapper {
      display: inline-block !important; } }

.date-range label,
.date-range input,
.custom-date-picker label,
.custom-date-picker input {
  margin-right: 10px; }

.date-range input,
.custom-date-picker input {
  padding-left: 3px !important; }

.custom-date-picker {
  margin-top: 15px; }

.react-datepicker__close-icon::after {
  background-color: #525149 !important;
  right: 10px !important; }

.react-datepicker__input-container input {
  height: 30px; }

.custom-date-input {
  background-color: #fff;
  border: 1px solid #C4C4C4 !important;
  height: 30px;
  width: 170px;
  text-align: left;
  padding-left: 10px; }

.terms {
  text-align: left; }

.notification-ecopetrol-verde {
  background-color: #C8D400;
  border-left: 8px solid #808800; }
  .notification-ecopetrol-verde .notification-title,
  .notification-ecopetrol-verde .notification-close span {
    color: #080800; }
  .notification-ecopetrol-verde .notification-message {
    color: #383b00;
    max-width: max-content; }

.notification-ecopetrol-naranja {
  background-color: #EE7402;
  border-left: 8px solid #a24f01; }
  .notification-ecopetrol-naranja .notification-title,
  .notification-ecopetrol-naranja .notification-close span {
    color: lighter(#EE7402, 40%); }
  .notification-ecopetrol-naranja .notification-message {
    color: lighter(#EE7402, 30%);
    max-width: max-content; }

.notification-ecopetrol-amarillo {
  background-color: #FFDD00;
  border-left: 8px solid #b39b00; }
  .notification-ecopetrol-amarillo .notification-title,
  .notification-ecopetrol-amarillo .notification-close span {
    color: #332c00; }
  .notification-ecopetrol-amarillo .notification-message {
    color: #665800;
    max-width: max-content; }

.action-buttons {
  min-width: 210px;
  text-align: right; }

.mobile-item .action-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center; }
  .mobile-item .action-buttons .btn {
    margin-top: 5px; }

html body .app.flex-row.align-items-center {
  height: 100vh; }

/*COLOR*/
.color-palette-green {
  color: #004136; }

.color-palette-lightgreen {
  color: #c8d400; }

.color-palette-yellow {
  color: #ffdd00; }

.color-palette-blue {
  color: #002e5f; }

.color-palette-orange {
  color: #ee7402; }

.color-palette-lightgray {
  color: #c4c4c4; }

.color-palette-gray {
  color: #525149; }

/*BACKGROUND*/
.background-palette-green {
  background-color: #004136; }

.background-palette-lightgreen {
  background-color: #c8d400; }

.background-palette-yellow {
  background-color: #ffdd00; }

.background-palette-blue {
  background: #002e5f; }

.background-palette-orange {
  background-color: #ee7402; }

.background-palette-lightgray {
  background-color: #c4c4c4; }

.background-palette-gray {
  background-color: #525149; }

/*BORDER*/
.border-palette-green {
  border-color: #004136; }

.border-palette-lightgreen {
  border-color: #c8d400; }

.border-palette-yellow {
  border-color: #ffdd00; }

.border-palette-blue {
  border: #002e5f; }

.border-palette-orange {
  border-color: #ee7402; }

.border-palette-lightgray {
  border-color: #c4c4c4; }

.border-palette-gray {
  border-color: #525149; }

.login-page {
  background-color: #fff;
  padding: 0;
  margin-top: 7% !important;
  border-radius: 10px !important;
  -webkit-box-shadow: 1px 1px 15px 0px silver;
  -moz-box-shadow: 1px 1px 15px 0px silver;
  box-shadow: 1px 1px 15px 0px silver; }

.login-background {
  background-size: cover;
  border-radius: 0 10px 10px 0 !important;
  background-position: center;
  background-color: #c0c0c0; }

.login-content {
  border-radius: 10px 0 0 10px !important;
  border: 0;
  /*margin-top: 20px !important;*/ }

.login-content h5.card-title.title {
  font-size: 22px; }

.login-content h5.card-title.title-complex {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 20px; }

.login-content h5.card-title.subtitle-complex {
  font-size: 14px; }

.login-content h5.card-title.subtitle {
  font-size: 16px; }

.login-content .logo {
  text-align: center;
  margin-bottom: 20px; }

.login-content .login-padding {
  padding-left: 10px;
  padding-right: 10px; }

@media (min-width: 576px) {
  .login-content .login-padding {
    padding-left: 5%;
    padding-right: 5%; } }

@media (min-width: 1200px) {
  .login-content .login-padding {
    padding-left: 20%;
    padding-right: 20%; } }

.login-content .login-padding-text {
  padding-left: 10%;
  padding-right: 10%; }

.login-header {
  border: 0;
  margin-bottom: 10%; }
  .login-header .logo img {
    width: 50%; }

.login-buttons-redmed {
  margin-top: 10% !important;
  width: 100%; }
  .login-buttons-redmed .update {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%; }

.login-buttons {
  margin-top: 30% !important; }
  .login-buttons .update {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%; }

.login-buttons-2 {
  margin-top: 20% !important; }
  .login-buttons-2 .update {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%; }

.login-buttons.margin-clear {
  margin-top: 5px !important; }

.login-btn {
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-radius: 20px;
  min-width: 200px; }

.test-btnlogin {
  width: 100%; }

.login-docnumber,
.login-docnumber:active,
.login-docnumber:focus {
  border-bottom: 2px solid #ffdd00 !important; }

.login-doctype:active,
.login-doctype:focus {
  border-bottom: 2px solid #ffdd00 !important; }

.pincode-input-container .pincode-input-text {
  padding: 5px !important;
  width: 15% !important;
  border: 0 !important;
  border-bottom: 2px solid #ffdd00 !important; }

.CircularProgressbar-path {
  transform: rotate(90deg);
  transform-origin: center center; }

.CircularProgressbar.inverted {
  width: 150px; }

.CircularProgressbar.inverted .CircularProgressbar-background {
  fill: #fff !important; }

.CircularProgressbar.inverted .CircularProgressbar-trail {
  stroke: #c4c4c4 !important;
  stroke-width: 4; }

.CircularProgressbar.inverted .CircularProgressbar-text {
  fill: #004136 !important;
  font-size: 5px; }

.CircularProgressbar.inverted .CircularProgressbar-path {
  stroke: #ee7402 !important;
  stroke-width: 4.35; }

.circular-text {
  position: static;
  margin-top: -110px;
  margin-bottom: 8px; }

.circular-cut {
  height: 80px;
  background-color: #fff;
  position: relative;
  margin-top: -10px; }

.link-reload-otp, .link-reload-otp-show, .link-reload-otp-hide {
  text-align: center;
  color: #004136;
  font-size: 13px;
  margin-top: 50px;
  text-decoration: underline; }

.link-reload-otp-show {
  visibility: visible !important; }

.link-reload-otp-hide {
  visibility: hidden !important; }

.link-reload-otp:hover, .link-reload-otp-show:hover, .link-reload-otp-hide:hover {
  cursor: pointer; }

@media (max-width: 360px) {
  .login-background {
    display: none; } }

/* Mensajes de error */
.message.error {
  color: red; }

.modal-content h5 {
  font-size: 1rem;
  font-weight: bold;
  color: #53534d; }

.modal-dialog.login-message {
  text-align: center; }
  .modal-dialog.login-message .modal-header,
  .modal-dialog.login-message .modal-footer {
    justify-content: center; }
  .modal-dialog.login-message .modal-title {
    width: 100%; }
  .modal-dialog.login-message .modal-header {
    border-bottom: 0px solid #e9ecef; }
  .modal-dialog.login-message img {
    margin-bottom: 20px; }
  .modal-dialog.login-message .modal-body {
    padding-top: 0px; }
  .modal-dialog.login-message .modal-content h5 {
    font-size: 1.4rem;
    font-weight: bold;
    color: #004136;
    margin-top: 10px;
    margin-bottom: 10px; }
  .modal-dialog.login-message .btn {
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px; }
  .modal-dialog.login-message .buttons {
    width: 100%; }
  .modal-dialog.login-message .message {
    text-align: center; }
  .modal-dialog.login-message .modal-footer {
    border-top: 0px solid #e9ecef; }
    .modal-dialog.login-message .modal-footer .div,
    .modal-dialog.login-message .modal-footer .col-xs-12 {
      width: 100% !important; }
      @media (min-width: 576px) {
        .modal-dialog.login-message .modal-footer .div,
        .modal-dialog.login-message .modal-footer .col-xs-12 {
          width: auto !important; } }

.modal-dialog.login-message.yesno .btn {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px; }

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem #c8d400; }

.btn-secondary.btn-disabled:not(:disabled):not(.disabled):active:focus,
.btn-secondary.btn-disabled.focus,
.btn-secondary.btn-disabled:focus {
  box-shadow: 0 0 0 0.2rem #c4c4c4; }

/*COLOR*/
.color-palette-green {
  color: #004136; }

.color-palette-lightgreen {
  color: #c8d400; }

.color-palette-yellow {
  color: #ffdd00; }

.color-palette-blue {
  color: #002e5f; }

.color-palette-orange {
  color: #ee7402; }

.color-palette-lightgray {
  color: #c4c4c4; }

.color-palette-gray {
  color: #525149; }

/*BACKGROUND*/
.background-palette-green {
  background-color: #004136; }

.background-palette-lightgreen {
  background-color: #c8d400; }

.background-palette-yellow {
  background-color: #ffdd00; }

.background-palette-blue {
  background: #002e5f; }

.background-palette-orange {
  background-color: #ee7402; }

.background-palette-lightgray {
  background-color: #c4c4c4; }

.background-palette-gray {
  background-color: #525149; }

/*BORDER*/
.border-palette-green {
  border-color: #004136; }

.border-palette-lightgreen {
  border-color: #c8d400; }

.border-palette-yellow {
  border-color: #ffdd00; }

.border-palette-blue {
  border: #002e5f; }

.border-palette-orange {
  border-color: #ee7402; }

.border-palette-lightgray {
  border-color: #c4c4c4; }

.border-palette-gray {
  border-color: #525149; }

.sidebar-logo img {
  width: 150px;
  height: auto;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 20px; }

body {
  font-size: 0.875rem; }

a,
a:hover {
  color: #004136; }

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom; }

/*
   * Sidebar
   */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1); }

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  /* Height of navbar */
  height: calc(100vh - 0px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */ }

.sidebar .nav-link {
  font-weight: 500;
  color: #333; }

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999; }

.sidebar .nav-link.active {
  color: #007bff; }

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit; }

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase; }

/*
   * Navbar
   */
.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25); }

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0; }

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1); }

.form-control-dark:focus {
  border-color: rgba(255, 255, 255, 0);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25); }

/*
   * Utilities
   */
.border-top {
  border-top: 1px solid #e5e5e5; }

.border-bottom {
  border-bottom: 1px solid #e5e5e5; }

.dashboard-title {
  color: #53534d;
  font-size: 1.08rem;
  font-weight: bolder; }

.mega-image {
  width: 150px;
  height: 150px; }

.mega-title {
  font-weight: bold;
  font-size: 1rem; }

.mega-subtitle {
  font-weight: normal;
  font-size: 0.8rem;
  color: #525149; }
  @media (min-width: 768px) {
    .mega-subtitle {
      font-size: 0.9rem; } }

.mega-detail .img-icon {
  width: 20px;
  height: 20px; }

.mega-detail p.card-text {
  margin-left: 10px;
  font-size: 0.8rem; }

.doctor {
  border-bottom: 5px solid #c8d400 !important;
  border-bottom-right-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  background-color: #ffffff; }
  .doctor .btn,
  .doctor .btn:active,
  .doctor .btn:hover,
  .doctor .btn:focus {
    font-size: 0.75rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    min-width: auto !important; }
  .doctor .change-button-container {
    text-align: center;
    margin-bottom: 5px; }

.modal-beneficiary-detail .buttons-row .btn,
.modal-beneficiary-detail .buttons-row .btn:active,
.modal-beneficiary-detail .buttons-row .btn:hover,
.modal-beneficiary-detail .buttons-row .btn:focus {
  font-size: 0.75rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  min-width: auto !important; }

.modal-beneficiary-detail .buttons-row div {
  text-align: center; }

.modal-beneficiary-detail .buttons-row .mega-container,
.modal-beneficiary-detail .buttons-row .oga-container {
  margin-top: 0px; }

.doctor-img-flecha {
  width: 30px;
  height: 30px;
  cursor: pointer; }

.beneficiary {
  border-left: 5px solid #FFDD00 !important;
  border-top-left-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  background-color: #ffffff;
  cursor: pointer; }

.beneficiary-title {
  font-weight: bold;
  font-size: 0.8rem; }

.beneficiary-subtitle {
  font-weight: normal;
  font-size: 0.8rem;
  color: #868682; }

@media (min-width: 576px) {
  .modal-beneficiary-detail {
    max-width: 540px; }
  .modal-proveedor-detail {
    max-width: 500px; } }

@media (min-width: 768px) {
  .modal-beneficiary-detail {
    width: 600px; }
  .modal-proveedor-detail {
    max-width: 500px; } }

@media (min-width: 992px) {
  .modal-beneficiary-detail {
    max-width: 900px;
    width: 900px; }
  .modal-proveedor-detail {
    max-width: 550px;
    width: 550px; } }

.beneficiaryHeaderBg {
  background-color: #ffffff; }

.modal-beneficiary-detail .beneficiaryDetail,
.modal-beneficiary-detail .doctors .card,
.modal-beneficiary-detail .doctors .list-group-item,
.modal-beneficiary-detail .modal-content,
.modal-beneficiary-detail .doctors {
  background-color: #F9FBFD; }

.modal-beneficiary-detail .doctors .card.principal-doctor {
  background-color: #fff; }
  .modal-beneficiary-detail .doctors .card.principal-doctor .card,
  .modal-beneficiary-detail .doctors .card.principal-doctor .card-body {
    background-color: #fff; }

.modal-hide-content .modal-content {
  display: none; }

.modal-dialog.without-close .modal-content {
  padding-top: 1rem; }

.beneficiaryHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  background-color: #fff; }

.modal-beneficiary-detail .doctors .doctor.beneficiary-doctor {
  border: 0px;
  border-bottom: 0px solid #c8d400 !important; }

.doctor.principal-doctor .card-body {
  text-align: center; }

.doctor.principal-doctor img {
  float: none !important; }

@media (min-width: 576px) {
  .doctor.principal-doctor .image-container {
    height: "100%";
    width: 80; }
  .doctor.principal-doctor .card-body {
    text-align: left; } }

.beneficiaryHeader h3 {
  color: #004237;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0px; }

.beneficiaryHeader p {
  color: #b1b1ae;
  font-size: 0.8rem;
  margin-bottom: 0px; }

.modal-beneficiary-detail {
  max-width: 100%; }
  .modal-beneficiary-detail .mega-container,
  .modal-beneficiary-detail .oga-container {
    margin-top: 20px; }
  .modal-beneficiary-detail .doctor-body {
    padding: 10px;
    padding-bottom: 20px;
    background-color: #F9FBFD; }
  .modal-beneficiary-detail .mega-container {
    border-right: 0px dashed #c4c4c4 !important; }
    @media (min-width: 768px) {
      .modal-beneficiary-detail .mega-container {
        border-right: 2px dashed #c4c4c4 !important; } }

.proveedorDetail .mega-container,
.AppointmentHistoryDetail .mega-container {
  border-right: none !important; }

.mega-detail .detail-item {
  margin-top: 15px;
  margin-bottom: 15px; }
  .mega-detail .detail-item .title {
    color: #525149;
    font-size: 0.8rem;
    margin-bottom: 0px; }
  .mega-detail .detail-item .value {
    font-size: 0.9rem; }

.doctor-not-assigned {
  background-color: rgba(255, 255, 255, 0);
  border: 3px dotted rgba(0, 0, 0, 0.125);
  padding-bottom: 10px; }
  .doctor-not-assigned .card {
    background-color: rgba(255, 255, 255, 0); }

.interest-links {
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important; }
  @media (min-width: 768px) {
    .interest-links {
      width: 80%; } }
  .interest-links p {
    white-space: nowrap;
    font-size: 0.7rem; }
  .interest-links .card {
    border-bottom: 3px solid #EE7402 !important;
    border-bottom-right-radius: 1.2rem !important;
    border-bottom-left-radius: 1.2rem !important;
    min-height: 50px;
    height: 100px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-top: 10px; }
    .interest-links .card img {
      margin-top: 10px; }

.interest-links.slide {
  width: 60%; }
  @media (min-width: 768px) {
    .interest-links.slide {
      width: 90%; } }

.magazine {
  width: 90%; }
  .magazine .card,
  .magazine .card-body {
    background-color: rgba(255, 255, 255, 0); }
  .magazine .magazine-image {
    width: 95%;
    height: auto; }
  @media (min-width: 768px) {
    .magazine {
      width: 70%; } }

.faq .card-header {
  padding-right: 50px !important; }
  .faq .card-header .highlight {
    color: #EE7402; }

.faq .card-body {
  color: #525149 !important;
  border-left: 2px solid #525149 !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding-left: 20px !important;
  padding-right: 50px !important; }
  .faq .card-body .highlight {
    color: #EE7402; }

.faq .card,
.faq .card-body,
.faq .card-header,
.faq h5,
.faq .btn {
  background-color: rgba(255, 255, 255, 0) !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0px;
  white-space: initial !important;
  text-align: left !important;
  color: #004136 !important;
  font-size: 1rem !important;
  text-transform: none !important; }

.faq .card {
  border-bottom: 2px solid #525149 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-top: 20px !important;
  padding-bottom: 20px !important;
  position: relative !important; }

.faq .card:last-child,
.faq .card.expanded {
  border-bottom: 0px solid #525149 !important; }

.faq .card.expanded {
  padding-bottom: 0px !important; }

.faq .collapse.show {
  margin-top: 40px !important;
  margin-bottom: 20px !important; }

.faq .icon-action {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  cursor: pointer !important; }

/* Sliders */
.slick-prev:before,
.slick-next:before {
  color: #004136;
  font-size: 25px;
  cursor: pointer; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.5;
  color: #C4C4C4;
  cursor: auto; }

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #004136; }

.sidebar-links img {
  margin-right: 15px; }

.sidebar-links .inactive a {
  color: #525149; }

.sidebar-links a {
  font-size: 0.94rem; }

.hamburguer {
  margin-top: 20px;
  margin-left: 0px;
  display: block;
  float: left; }
  .hamburguer .hamburguer-icon {
    font-size: 1.5em; }

.header-logo img {
  width: 150px;
  height: auto; }

.page-footer {
  padding-left: 15px;
  padding-right: 15px; }

.page-footer.sticky {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color: #525149;
  font-size: 0.72rem;
  font-weight: 500; }
  .page-footer.sticky a {
    font-weight: 700; }

.footer-copyright {
  font-size: 0.875rem; }

.principal-container {
  margin-bottom: 50px; }

.principal-title {
  margin-bottom: 1.4rem !important;
  color: #004136; }
  .principal-title h1 {
    font-weight: bolder; }

h3 {
  color: #004136;
  font-size: 1.5rem; }

.breadcrumb {
  background-color: rgba(255, 255, 255, 0);
  padding: 0rem 1rem;
  margin-bottom: 0rem; }

.breadcrumb-item {
  font-size: 0.75rem; }

.breadcrumb-item a {
  color: #525149;
  text-decoration: underline; }

.breadcrumb-item.active {
  color: #004136; }

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0.2rem;
  color: #525149;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.2rem; }

h2 {
  color: #525149;
  font-size: 1.05rem;
  font-weight: bold;
  margin-bottom: 25px; }

.modal-dialog.login-message.cancel-appointment {
  text-align: left; }
  .modal-dialog.login-message.cancel-appointment .modal-content h5 {
    text-align: center; }
  .modal-dialog.login-message.cancel-appointment .modal-body {
    padding-left: 40px;
    padding-right: 40px; }

/*COLOR*/
.color-palette-green {
  color: #004136; }

.color-palette-lightgreen {
  color: #c8d400; }

.color-palette-yellow {
  color: #ffdd00; }

.color-palette-blue {
  color: #002e5f; }

.color-palette-orange {
  color: #ee7402; }

.color-palette-lightgray {
  color: #c4c4c4; }

.color-palette-gray {
  color: #525149; }

/*BACKGROUND*/
.background-palette-green {
  background-color: #004136; }

.background-palette-lightgreen {
  background-color: #c8d400; }

.background-palette-yellow {
  background-color: #ffdd00; }

.background-palette-blue {
  background: #002e5f; }

.background-palette-orange {
  background-color: #ee7402; }

.background-palette-lightgray {
  background-color: #c4c4c4; }

.background-palette-gray {
  background-color: #525149; }

/*BORDER*/
.border-palette-green {
  border-color: #004136; }

.border-palette-lightgreen {
  border-color: #c8d400; }

.border-palette-yellow {
  border-color: #ffdd00; }

.border-palette-blue {
  border: #002e5f; }

.border-palette-orange {
  border-color: #ee7402; }

.border-palette-lightgray {
  border-color: #c4c4c4; }

.border-palette-gray {
  border-color: #525149; }

.react-bootstrap-table table thead {
  display: none; }
  @media (min-width: 768px) {
    .react-bootstrap-table table thead {
      display: table-header-group; } }

.react-bootstrap-table .table thead th {
  background: rgba(255, 255, 255, 0) !important;
  border-top: 0px solid #dee2e6;
  border-bottom: 0px solid #dee2e6;
  color: #525149 !important;
  font-size: 0.7rem;
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.react-bootstrap-table .table thead th:first-child {
  padding-left: 18px !important; }

.react-bootstrap-table table {
  background-color: rgba(255, 255, 255, 0) !important;
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  margin-top: 10px !important;
  table-layout: auto !important;
  font-size: 0.75rem !important;
  color: #525149 !important; }

.react-bootstrap-table td {
  border: solid 1px #000;
  border-style: solid none;
  padding: 10px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background-color: cyan; }

.react-bootstrap-table table thead tr {
  cursor: auto; }

.react-bootstrap-table td:first-child {
  border-left: 3px solid #ffdd00 !important;
  border-top-left-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  max-width: 300px;
  min-width: 50px; }

.react-bootstrap-table td:first-child.react-bs-table-no-data {
  border-left: 3px solid #ffffff !important;
  cursor: auto; }

.react-bootstrap-table td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px; }

.react-bootstrap-table .proveedor {
  position: relative; }
  .react-bootstrap-table .proveedor .avatar {
    width: 30px;
    position: absolute;
    top: 0;
    left: 5px; }
  .react-bootstrap-table .proveedor .name {
    padding-left: 35px;
    color: #004136;
    font-weight: bold; }

.react-bootstrap-table .btn {
  margin-top: 10px; }

.with-onclick .react-bootstrap-table tr {
  cursor: pointer; }

.react-bootstrap-table-pagination .btn.dropdown-toggle {
  padding-left: 12px !important;
  padding-right: 12px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  min-width: auto; }

.react-bootstrap-table-pagination div:first-child {
  text-align: center;
  margin-bottom: 10px; }

.react-bootstrap-table-pagination .pagination {
  float: none; }

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: center; }

.appointment-card img {
  position: absolute;
  top: 0;
  left: 0; }

.appointment-card p {
  line-height: 1.4rem;
  padding-left: 25px;
  position: relative; }

.appointment-card .card-body {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.appointment-doctor {
  line-height: 1rem;
  font-size: 0.7rem; }
  .appointment-doctor .name {
    color: #004136;
    font-weight: bold;
    font-size: 0.9rem; }

@media (min-width: 400px) {
  .react-bootstrap-table-pagination div:first-child {
    display: block;
    float: left;
    width: auto; }
  .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list {
    display: block;
    float: right;
    width: auto; }
  .react-bootstrap-table-pagination .pagination {
    float: right; }
  .react-bootstrap-table-pagination.row {
    display: block; } }

.react-select__control,
.react-select__option {
  cursor: pointer !important; }

.lines-formatter {
  display: block;
  /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 63px;
  line-height: 21px;
  width: 200px; }

.tab-red-medica {
  margin-top: 15px; }
  .tab-red-medica .nav-item {
    display: flex; }
  .tab-red-medica .nav-item .nav-link {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-bottom: -1px;
    font-size: 0.65rem; }
  .tab-red-medica .nav-link {
    cursor: pointer;
    color: #525149 !important;
    text-align: center;
    padding: 0.3rem 0.5rem; }
  .tab-red-medica .nav-link.active {
    color: #EE7402 !important;
    font-weight: bold;
    border: 0px solid rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 3px solid #EE7402; }
  @media (min-width: 576px) {
    .tab-red-medica .nav-item .nav-link {
      font-size: 0.875rem; }
    .tab-red-medica .nav-link {
      padding: 0.5rem 1rem; } }

.form-control-clear {
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 22px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center; }

::-ms-clear {
  display: none; }

.has-clear {
  position: relative; }
  .has-clear input {
    padding-right: 22px; }

.no-data-indication {
  color: #525149;
  margin-top: 20px; }

.doctortable .btn-ecopetrol,
.doctortable .btn.btn-ecopetrol:active,
.doctortable .btn.btn-ecopetrol:hover,
.doctortable .btn.btn-ecopetrol:focus {
  font-size: 0.75rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  min-width: auto !important; }

.doctortable tr {
  cursor: auto; }

/*COLOR*/
.color-palette-green {
  color: #004136; }

.color-palette-lightgreen {
  color: #c8d400; }

.color-palette-yellow {
  color: #ffdd00; }

.color-palette-blue {
  color: #002e5f; }

.color-palette-orange {
  color: #ee7402; }

.color-palette-lightgray {
  color: #c4c4c4; }

.color-palette-gray {
  color: #525149; }

/*BACKGROUND*/
.background-palette-green {
  background-color: #004136; }

.background-palette-lightgreen {
  background-color: #c8d400; }

.background-palette-yellow {
  background-color: #ffdd00; }

.background-palette-blue {
  background: #002e5f; }

.background-palette-orange {
  background-color: #ee7402; }

.background-palette-lightgray {
  background-color: #c4c4c4; }

.background-palette-gray {
  background-color: #525149; }

/*BORDER*/
.border-palette-green {
  border-color: #004136; }

.border-palette-lightgreen {
  border-color: #c8d400; }

.border-palette-yellow {
  border-color: #ffdd00; }

.border-palette-blue {
  border: #002e5f; }

.border-palette-orange {
  border-color: #ee7402; }

.border-palette-lightgray {
  border-color: #c4c4c4; }

.border-palette-gray {
  border-color: #525149; }

@font-face {
  font-family: 'Open Sans';
  /* src: url('https://fonts.googleapis.com/css?family=Open+Sans'); */
  src: url('https://fonts.googleapis.com/css?family=Open+Sans:700" rel="stylesheet'); }

@font-face {
  font-family: 'Ecopetrol';
  /* src: url('https://fonts.googleapis.com/css?family=Open+Sans'); */
  src: url("assets/fonts/Ecopetrol.ttf"); }
