@import "_palette";

.login-page {
  background-color: #fff;
  padding: 0;
  margin-top: 7% !important;
  border-radius: 10px !important;
  -webkit-box-shadow: 1px 1px 15px 0px rgba(192, 192, 192, 1);
  -moz-box-shadow: 1px 1px 15px 0px rgba(192, 192, 192, 1);
  box-shadow: 1px 1px 15px 0px rgba(192, 192, 192, 1);
}

.login-background {
  background-size: cover;
  border-radius: 0 10px 10px 0 !important;
  background-position: center;
  background-color: #c0c0c0;
}
.login-content {
  border-radius: 10px 0 0 10px !important;
  border: 0;
  /*margin-top: 20px !important;*/
}
.login-content h5.card-title.title {
  font-size: 22px;
}
.login-content h5.card-title.title-complex {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.login-content h5.card-title.subtitle-complex {
  font-size: 14px;
}
.login-content h5.card-title.subtitle {
  font-size: 16px;
}
.login-content .logo {
  text-align: center;
  margin-bottom: 20px;
}

.login-content .login-padding {
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 576px) {
  .login-content .login-padding {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (min-width: 1200px) {
  .login-content .login-padding {
    padding-left: 20%;
    padding-right: 20%;
  }
}

.login-content .login-padding-text {
  padding-left: 10%;
  padding-right: 10%;
}

.login-header {
  border: 0;
  margin-bottom: 10%;
  .logo img {
    width: 50%;
  }
}

.login-buttons-redmed {
  margin-top: 10% !important;
  width: 100%;
  .update {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
}

.login-buttons {
  margin-top: 30% !important;
  .update {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
}
.login-buttons-2 {
  margin-top: 20% !important;
  .update {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
}
.login-buttons.margin-clear {
  margin-top: 5px !important;
}

.login-btn {
  margin-left: 5px !important;
  margin-right: 5px !important;
  border-radius: 20px;
  min-width: 200px;
}
.test-btnlogin {
  width: 100%;
}

.login-docnumber,
.login-docnumber:active,
.login-docnumber:focus {
  border-bottom: 2px solid $color-yellow !important;
}

.login-doctype:active,
.login-doctype:focus {
  border-bottom: 2px solid $color-yellow !important;
}

.pincode-input-container {
  .pincode-input-text {
    padding: 5px !important;
    width: 15% !important;
    border: 0 !important;
    border-bottom: 2px solid $color-yellow !important;
  }
}

.CircularProgressbar-path {
  transform: rotate(90deg);
  transform-origin: center center;
}
.CircularProgressbar.inverted {
  width: 150px;
}
.CircularProgressbar.inverted .CircularProgressbar-background {
  fill: #fff !important;
}
.CircularProgressbar.inverted .CircularProgressbar-trail {
  stroke: $color-lightgray !important;
  stroke-width: 4;
}
.CircularProgressbar.inverted .CircularProgressbar-text {
  fill: $color-green !important;
  font-size: 5px;
}

.CircularProgressbar.inverted .CircularProgressbar-path {
  stroke: $color-orange !important;
  stroke-width: 4.35;
}
.circular-text {
  position: static;
  margin-top: -110px;
  margin-bottom: 8px;
}

.circular-cut {
  height: 80px;
  background-color: #fff;
  position: relative;
  margin-top: -10px;
}

.link-reload-otp {
  text-align: center;
  color: $color-green;
  font-size: 13px;
  margin-top: 50px;
  text-decoration: underline;
}
.link-reload-otp-show {
  @extend .link-reload-otp;
  visibility: visible !important;
}
.link-reload-otp-hide {
  @extend .link-reload-otp;
  visibility: hidden !important;
}

.link-reload-otp:hover {
  cursor: pointer;
}
@media (max-width: 360px) {
  .login-background {
    display: none;
  }
}

/* Mensajes de error */
.message.error {
  color: red;
}

.modal-content h5 {
  font-size: 1rem;
  font-weight: bold;
  color: #53534d;
}
.modal-dialog.login-message {
  text-align: center;
  .modal-header,
  .modal-footer {
    justify-content: center;
  }
  .modal-title {
    width: 100%;
  }
  .modal-header {
    border-bottom: 0px solid #e9ecef;
  }
  img {
    margin-bottom: 20px;
  }
  .modal-body {
    padding-top: 0px;
  }
  .modal-content h5 {
    font-size: 1.4rem;
    font-weight: bold;
    color: $dark-green;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .btn {
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .buttons {
    width: 100%;
  }
  .message {
    text-align: center;
  }
  .modal-footer {
    border-top: 0px solid #e9ecef;
    .div,
    .col-xs-12 {
      width: 100% !important;
      @media (min-width: 576px) {
        width: auto !important;
      }
    }
  }
}
.modal-dialog.login-message.yesno {
  .btn {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgb(200, 212, 0);
}
.btn-secondary.btn-disabled:not(:disabled):not(.disabled):active:focus,
.btn-secondary.btn-disabled.focus,
.btn-secondary.btn-disabled:focus {
  box-shadow: 0 0 0 0.2rem #c4c4c4;
}
