@import "_palette";
.react-bootstrap-table {
  table thead {
    display: none;
    @media (min-width: 768px) {
      display: table-header-group;
    }
  }
  .table thead th {
    background: rgba(255, 255, 255, 0) !important;
    border-top: 0px solid #dee2e6;
    border-bottom: 0px solid #dee2e6;
    color: $dark-gray !important;
    font-size: 0.7rem;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .table thead th:first-child {
    padding-left: 18px !important;
  }
  table {
    background-color: rgba(255, 255, 255, 0) !important;
    border-collapse: separate !important;
    border-spacing: 0 10px !important;
    margin-top: 10px !important;
    table-layout: auto !important;
    font-size: 0.75rem !important;
    color: $dark-gray !important;
  }
  td {
    border: solid 1px #000;
    border-style: solid none;
    padding: 10px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    background-color: cyan;
  }
  table thead tr {
    cursor: auto;
  }

  td:first-child {
    border-left: 3px solid #ffdd00 !important;
    border-top-left-radius: 1.2rem !important;
    border-bottom-left-radius: 1.2rem !important;
    max-width: 300px;
    min-width: 50px;
  }
  td:first-child.react-bs-table-no-data {
    border-left: 3px solid #ffffff !important;
    cursor: auto;
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .proveedor {
    position: relative;
    .avatar {
      width: 30px;
      position: absolute;
      top: 0;
      left: 5px;
    }
    .name {
      padding-left: 35px;
      color: $dark-green;
      font-weight: bold;
    }
  }
  .btn {
    margin-top: 10px;
  }
}
.with-onclick .react-bootstrap-table {
  tr {
    cursor: pointer;
  }
}
.react-bootstrap-table-pagination {
  .btn.dropdown-toggle {
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    min-width: auto;
  }
  div:first-child {
    text-align: center;
    margin-bottom: 10px;
  }
  .pagination {
    float: none;
  }
  .react-bootstrap-table-pagination-list {
    display: flex;
    justify-content: center;
  }
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary.focus,
  .btn-secondary:focus {
    //box-shadow: none;
  }
}
.appointment-card {
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
  p {
    line-height: 1.4rem;
    padding-left: 25px;
    position: relative;
  }
  .card-body {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.appointment-doctor {
  line-height: 1rem;
  font-size: 0.7rem;
  .name {
    color: $dark-green;
    font-weight: bold;
    font-size: 0.9rem;
  }
}
@media (min-width: 400px) {
  .react-bootstrap-table-pagination {
    div:first-child {
      display: block;
      float: left;
      width: auto;
    }
    .react-bootstrap-table-pagination-list {
      display: block;
      float: right;
      width: auto;
    }
    .pagination {
      float: right;
    }
  }
  .react-bootstrap-table-pagination.row {
    display: block;
  }
}

// Form fields
.react-select__control,
.react-select__option {
  cursor: pointer !important;
}
.lines-formatter {
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 63px;
  line-height: 21px;
  width: 200px;
}

.tab-red-medica {
  margin-top: 15px;
  .nav-item {
    display: flex;
  }
  .nav-item .nav-link {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-bottom: -1px;
    font-size: 0.65rem;
  }
  .nav-link {
    cursor: pointer;
    color: $dark-gray !important;
    text-align: center;
    padding: 0.3rem 0.5rem;
  }
  .nav-link.active {
    color: $app-orange !important;
    font-weight: bold;
    border: 0px solid rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 3px solid $app-orange;
  }
  @media (min-width: 576px) {
    .nav-item .nav-link {
      font-size: 0.875rem;
    }
    .nav-link {
      padding: 0.5rem 1rem;
    }
  }
}
.form-control-clear {
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 22px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
::-ms-clear {
  display: none;
}
.has-clear {
  position: relative;
  input {
    padding-right: 22px;
  }
}

.no-data-indication {
  color: $dark-gray;
  margin-top: 20px;
}

.doctortable {
  .btn-ecopetrol,
  .btn.btn-ecopetrol:active,
  .btn.btn-ecopetrol:hover,
  .btn.btn-ecopetrol:focus {
    font-size: 0.75rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    min-width: auto !important;
  }
  tr {
    cursor: auto;
  }
}
