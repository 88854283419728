
$color-green: #004136;
$color-lightgreen: #c8d400;
$color-yellow: #ffdd00;
$color-blue: #002e5f;
$color-orange: #ee7402;
$color-lightgray: #c4c4c4;
$color-gray: #525149;

  $app-black: #000000;
  $app-orange: #EE7402;
  $app-yellow: #FFDD00;
  $app-white: #FFFFFF;
  $dark-gray: #525149;
  $dark-green: #004136;
  $dark-white: #F9FBFD;
  $ulight-gray: #FDFDFD;
  $light-gray: #C4C4C4;
  $light-green: #C8D400;
  $screen-gray: #F3F5F7;
  $text-gray: #A1A2A2;

/*COLOR*/
.color-palette-green {
  color: #004136;
}
.color-palette-lightgreen {
  color: #c8d400;
}
.color-palette-yellow {
  color: #ffdd00;
}
.color-palette-blue {
  color: #002e5f;
}
.color-palette-orange {
  color: #ee7402;
}
.color-palette-lightgray {
  color: #c4c4c4;
}
.color-palette-gray {
  color: #525149;
}

/*BACKGROUND*/
.background-palette-green {
  background-color: #004136;
}
.background-palette-lightgreen {
  background-color: #c8d400;
}
.background-palette-yellow {
  background-color: #ffdd00;
}
.background-palette-blue {
  background: #002e5f;
}
.background-palette-orange {
  background-color: #ee7402;
}
.background-palette-lightgray {
  background-color: #c4c4c4;
}
.background-palette-gray {
  background-color: #525149;
}

/*BORDER*/
.border-palette-green {
  border-color: #004136;
}
.border-palette-lightgreen {
  border-color: #c8d400;
}
.border-palette-yellow {
  border-color: #ffdd00;
}
.border-palette-blue {
  border: #002e5f;
}
.border-palette-orange {
  border-color: #ee7402;
}
.border-palette-lightgray {
  border-color: #c4c4c4;
}
.border-palette-gray {
  border-color: #525149;
}
